import { useState, useEffect, useRef, useCallback } from 'react'
import debounce from 'lodash/debounce'
import CONFIG from 'isomorphic-config'
import type { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint } from '@/types'
import { fireFilterBarGA4Event } from './FilterBar/ga4'
import { top20Filters, cityIdExistsInTop20Filters } from './utils'

type UseTypeAheadOptions = {
  searchValue: string | null
  searchKey: string
  showHotelTypeaheadFilters?: boolean
  onLoaded?: () => void
  typeAheadEndpoint: TypeAheadConfigEndpoint
  debounceTime?: number
}

type TypeAheadState = {
  loading: boolean
  error: unknown
  items: LOCATION_SEARCH_TYPE[]
  city?: string
}

function useTypeAhead({
  searchValue,
  searchKey,
  showHotelTypeaheadFilters = false,
  onLoaded = () => null,
  typeAheadEndpoint,
  debounceTime = 300
}: UseTypeAheadOptions) {
  const [state, setState] = useState<TypeAheadState>({
    loading: false,
    error: null,
    items: []
  })

  const prevSearchValueRef = useRef<string | null>(null)
  const onLoadedRef = useRef(onLoaded)
  useEffect(() => {
    onLoadedRef.current = onLoaded
  }, [onLoaded])

  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const fetchTypeaheadData = useCallback(
    (query: string) => {
      const locationConfig = CONFIG.client.typeahead.location
      const isRCSearchEndPoint = typeAheadEndpoint === 'cars'
      const parsedURLSuffix = isRCSearchEndPoint
        ? ''
        : locationConfig.suffix[typeAheadEndpoint]
      const parseURLSuffixQS = isRCSearchEndPoint
        ? locationConfig.querystring.cars
        : ''
      const url = `${locationConfig.endpoint[typeAheadEndpoint]}${query}${parsedURLSuffix}${parseURLSuffixQS}`

      setState(prev => ({ ...prev, loading: true }))

      fetch(url)
        .then(res => res.json())
        .then(
          ({ searchItems = [] }: { searchItems: LOCATION_SEARCH_TYPE[] }) => {
            if (!mountedRef.current) return

            setState(prev => ({ ...prev, loading: false, items: searchItems }))
            onLoadedRef.current()

            const cityFilters =
              showHotelTypeaheadFilters &&
              searchItems.length > 0 &&
              cityIdExistsInTop20Filters(searchItems[0].cityID)
                ? top20Filters[searchItems[0].cityID]
                : []

            if (showHotelTypeaheadFilters && cityFilters.length > 0) {
              fireFilterBarGA4Event()
            }
          }
        )
        .catch(error => {
          if (!mountedRef.current) return
          setState(prev => ({ ...prev, loading: false, error }))
          onLoadedRef.current()
        })
    },
    [typeAheadEndpoint, showHotelTypeaheadFilters]
  )
  const debouncedFetchRef = useRef<ReturnType<typeof debounce> | null>(null)

  // Initialize or update the debounced fetch function when dependencies change
  useEffect(() => {
    if (debouncedFetchRef.current) {
      debouncedFetchRef.current.cancel()
    }

    debouncedFetchRef.current = debounce((query: string) => {
      fetchTypeaheadData(query)
    }, debounceTime)

    return () => {
      if (debouncedFetchRef.current) {
        debouncedFetchRef.current.cancel()
      }
    }
  }, [fetchTypeaheadData, debounceTime])

  useEffect(() => {
    if (prevSearchValueRef.current !== searchValue) {
      prevSearchValueRef.current = searchValue

      if (searchValue && searchValue.trim()) {
        if (debouncedFetchRef.current) {
          debouncedFetchRef.current(searchValue)
        }
      } else {
        setState({ loading: false, error: null, items: [] })
      }
    }
  }, [searchValue, searchKey])

  const search = useCallback((value: string) => {
    if (value.trim() && debouncedFetchRef.current) {
      debouncedFetchRef.current(value)
    }
  }, [])

  return {
    ...state,
    search
  }
}

export default useTypeAhead
