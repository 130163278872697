import React, { useCallback } from 'react'
import { Box, SrOnly } from 'pcln-design-system'
import {
  type GetItemPropsOptions,
  type GetMenuPropsOptions,
  type GetPropsCommonOptions
} from 'downshift'
import INPUT_MIN_WIDTH from '@/constants/dropdown'
import type { LOCATION_SEARCH_TYPE, TypeAheadConfigEndpoint } from '@/types'
import type { SelectedTopFilters } from '@/components/Hotels/types'
import useSeti from '@/hooks/useSeti'
import DropdownCard from './DropdownCard.styled'
import SearchItem from './SearchItem'
import RenderCurrentLocation from './RenderCurrentLocation'
import RenderRecentSearches from './RenderRecentSearches'
import { type CurrentLocationMachineState } from '../../../machines/currentLocationMachine'
import TypeaheadPenny from '../TypeaheadPenny'
import FilterBar from '../FilterBar/FilterBar'
import { cityIdExistsInTop20Filters, top20Filters } from '../utils'
import useTypeAhead from '../useTypeAhead'

type DropdownProps = {
  isOpen: boolean
  highlightedIndex: number | null
  getItemProps: (
    options: GetItemPropsOptions<LOCATION_SEARCH_TYPE>
  ) => Record<string, unknown>
  getMenuProps: (
    options?: GetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => Record<string, unknown>
  searchKey: string
  searchValue: string | null
  clearItems: () => void
  setHighlightedIndex: (index: number) => void
  showCurrentLocation?: boolean
  isMobile?: boolean
  current?: CurrentLocationMachineState
  handleRequestCurrentLocationClick?: () => void
  typeAheadEndpoint: TypeAheadConfigEndpoint
  recentSearches?: LOCATION_SEARCH_TYPE[]
  isTwoLineDisplay?: boolean
  recentOrRecommendationTitle: string
  nearbyAirportsFeatureEnabled?: boolean
  shouldDisplayPenny?: boolean
  setSelectedTopFilters?: (
    value: React.SetStateAction<SelectedTopFilters>
  ) => void
  selectedTopFilters?: SelectedTopFilters
  setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
  showHotelTypeaheadFilters: boolean
}

function Dropdown({
  isOpen,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  searchKey,
  searchValue,
  clearItems,
  current,
  handleRequestCurrentLocationClick,
  showCurrentLocation = false,
  setHighlightedIndex,
  isMobile = false,
  typeAheadEndpoint,
  recentSearches = [],
  recentOrRecommendationTitle,
  isTwoLineDisplay,
  shouldDisplayPenny,
  setSelectedTopFilters,
  selectedTopFilters,
  setIsPopoverOpen,
  showHotelTypeaheadFilters
}: DropdownProps) {
  const handleTypeaheadLoaded = useCallback(() => {
    clearItems()
    setHighlightedIndex(0)
  }, [clearItems, setHighlightedIndex])
  const isDebounceSetiActive = useSeti('TYPEAHEAD_DEBOUNCE') === 'VARIANT'
  const { items } = useTypeAhead({
    searchValue,
    searchKey,
    typeAheadEndpoint,
    showHotelTypeaheadFilters,
    onLoaded: handleTypeaheadLoaded,
    debounceTime: isDebounceSetiActive ? 100 : 300
  })

  if (!isOpen) {
    return null
  }

  const isCardVisible = isOpen && items.length > 0
  const isRecentSearchesVisible =
    isOpen && recentSearches.length > 0 && searchValue === ''

  const cityFilters =
    showHotelTypeaheadFilters &&
    items.length > 0 &&
    cityIdExistsInTop20Filters(items[0].cityID)
      ? top20Filters[items[0].cityID]
      : []

  return (
    <>
      {isCardVisible && (
        <SrOnly aria-live="polite">{items.length} number of results</SrOnly>
      )}
      <DropdownCard
        boxShadowSize={isMobile ? undefined : 'overlay-lg'}
        data-testid="typeahead-dropdown-card"
        color="background.lightest"
        borderRadius={shouldDisplayPenny ? ['none', null, 'xl'] : 'xl'}
        mt={isMobile ? 0 : 1}
        role="listbox"
        rounded={isMobile ? 'bottom' : undefined}
        width={1}
        minWidth={isMobile ? '' : INPUT_MIN_WIDTH}
        visible={
          isCardVisible || isRecentSearchesVisible || showCurrentLocation
        }
        {...getMenuProps({
          refKey: 'ref',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          isOpen
        })}
      >
        <Box px={[3, null, 2]} py={2}>
          {showHotelTypeaheadFilters && (
            <FilterBar
              cityFilters={isMobile ? cityFilters.slice(0, 3) : cityFilters}
              setSelectedTopFilters={setSelectedTopFilters}
              selectedTopFilters={selectedTopFilters}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          )}
          {showCurrentLocation && current && (
            <RenderCurrentLocation
              current={current}
              handleRequestCurrentLocationClick={
                handleRequestCurrentLocationClick
              }
            />
          )}
          {isRecentSearchesVisible ? (
            <RenderRecentSearches
              getItemProps={getItemProps}
              recentSearches={recentSearches}
              highlightedIndex={highlightedIndex}
              recentOrRecommendationTitle={recentOrRecommendationTitle}
            />
          ) : null}
          {isCardVisible
            ? items.map((item: LOCATION_SEARCH_TYPE, index: number) => (
                <SearchItem
                  isTwoLineDisplay={isTwoLineDisplay}
                  key={item.itemName}
                  isItemSelected={index === highlightedIndex}
                  index={index}
                  item={item}
                  getItemProps={getItemProps}
                />
              ))
            : null}
        </Box>
        {shouldDisplayPenny && (
          <TypeaheadPenny isRecentSearchesVisible={!!isRecentSearchesVisible} />
        )}
      </DropdownCard>
    </>
  )
}

export default Dropdown
