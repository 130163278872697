import React from 'react'
import { Text } from 'pcln-design-system'
import { Gps } from 'pcln-icons'
import { type CurrentLocationMachineState } from '../../../machines/currentLocationMachine'
import DropdownItem from './DropdownItem'

const renderDisplayText = (current: CurrentLocationMachineState) => {
  if (current.matches('pending')) {
    return 'Locating...'
  }
  if (current.matches('rejected')) {
    if (current.context.error) {
      return 'Having Issue Locating, Please try again'
    }
    return 'Location Sharing Disabled'
  }
  return 'Use Current Location'
}

type RenderCurrentLocationProps = {
  handleRequestCurrentLocationClick?: () => void
  current: CurrentLocationMachineState
}

function RenderCurrentLocation({
  handleRequestCurrentLocationClick,
  current
}: RenderCurrentLocationProps) {
  return (
    <DropdownItem
      {...(handleRequestCurrentLocationClick
        ? { onMouseDown: handleRequestCurrentLocationClick }
        : {})}
      mb={2}
    >
      <Gps color="primary" mx={2} size={24} />
      <Text textStyle="subheading5" py={2} mx={2}>
        {renderDisplayText(current)}
      </Text>
    </DropdownItem>
  )
}

export default RenderCurrentLocation
